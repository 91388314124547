import abinsbaumhaus from './images/game-davosklosters-abinsbaumhaus.png';
import gaemsebruecke from './images/game-rhb-gaemsebruecke.png';
import libellensprung from './images/game-savognin-libellensprung.png';
import segelmeister from './images/game-surselva-segelmeister.png';
import { SceneInformation, scenesInformation } from './sceneInformation';

export type GameID = keyof typeof gamesInformation;

export interface GameInformation {
  url: string;
  metaTitle: string;
  metaDescription: string;
  overlayTitle?: string;
  overlayImage?: string;
  overlayScene?: SceneInformation;
}

export const gamesInformation = {
  baumhaus: {
    url: '/baumhaus',
    metaTitle: 'Kinderwelt Graubünden – Ab ins Baumhaus',
    metaDescription:
      'Hilf dem Eichhörnchen, von Ast zu Ast zu springen. Nur so kommt es ganz nach oben in sein Baumhaus.',
    overlayTitle: 'Ab ins Baumhaus',
    overlayImage: abinsbaumhaus,
    overlayScene: scenesInformation.davos
  } as GameInformation,
  gaemsebruecke: {
    url: '/gaemsebruecke',
    metaTitle: 'Kinderwelt Graubünden – Gämsebrücke',
    metaDescription:
      'Die kleine Gämse braucht deine Hilfe. Baue ihr passende Brücken, damit sie sicher an ihr Ziel kommt.',
    overlayImage: gaemsebruecke,
    overlayTitle: 'Gämsebrücke',
    overlayScene: scenesInformation.rhb
  } as GameInformation,
  libellensprung: {
    url: '/libellensprung',
    metaTitle: 'Kinderwelt Graubünden – Summ, summ, Libellensprung',
    metaDescription:
      'Die Libelle will den Fluss überqueren. Hilf ihr beim Fliegen, damit sie es mit trockenen Flügeln über das Wasser schafft.',
    overlayTitle: 'Libellensprung',
    overlayImage: libellensprung,
    overlayScene: scenesInformation.savognin
  } as GameInformation,
  segelmeister: {
    url: '/segelmeister',
    metaTitle: 'Kinderwelt Graubünden – Segelmeister',
    metaDescription:
      'Der Bartgeier segelt wie ein Meister durch den Himmel. Hilf ihm dabei, alle Hindernisse zu umfliegen und in der Luft zu bleiben.',
    overlayTitle: 'Segelmeister',
    overlayImage: segelmeister,
    overlayScene: scenesInformation.surselva
  } as GameInformation,
  ruablijagd: {
    url: '/ruabli-jagd',
    metaTitle: 'Kinderwelt Graubünden – Rüabli-Jagd',
    metaDescription:
      'Die Rüebli-Jagd hat begonnen! Fange die Rüben, die zu der Farbe der Rübennase des Schneemanns passen, und sammle so wichtige Punkte.'
  } as GameInformation,
  wintervolley: {
    url: '/winter-volley',
    metaTitle: 'Kinderwelt Graubünden – Winter-Volley',
    metaDescription:
      'Bist du bereit für eine Schneeballschlacht? Wirf möglichst viele Schneebälle bis zum Schneemann und sammle wertvolle Punkte.'
  } as GameInformation,
  schneeverlochata: {
    url: '/schnee-verlochata',
    metaTitle: 'Kinderwelt Graubünden – Schnee-Verlochata',
    metaDescription:
      'Wie gut kannst du mit deinem Schneeball zielen? Wirf die Schneebälle genau ins Golfloch und sammle unterwegs verschiedene Bonuspunkte.'
  } as GameInformation,
  hasahopsa: {
    url: '/hasahopsa',
    metaTitle: 'Kinderwelt Graubünden – Hasa-Hopsa',
    metaDescription:
      'Hilf dem Hasen, möglichst viele Karotten einzufangen. Aber Achtung, es hat viele Hindernisse auf dem Weg, mit denen der Hase nicht zusammenstossen darf.'
  } as GameInformation
};
