/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import favicon32 from '../../static/summer/favicon-32x32.png';
import favicon16 from '../../static/summer/favicon-16x16.png';
import appleTouchIcon from '../../static/summer/apple-touch-icon.png';
import safariPinnedTab from '../../static/summer/safari-pinned-tab.svg';
import androidChromeIcon from '../../static/summer/appicon-512x512.png';
// #WINTER#
// import favicon32 from '../../static/winter/favicon-32x32.png';
// import favicon16 from '../../static/winter/favicon-16x16.png';
// import appleTouchIcon from '../../static/winter/apple-touch-icon.png';
// import safariPinnedTab from '../../static/winter/safari-pinned-tab.svg';
// import androidChromeIcon from '../../static/winter/android-chrome-256x256.png';
import { SceneID, scenesInformation, SceneInformation } from './Navigation/sceneInformation';
import { GameID, gamesInformation, GameInformation } from './Navigation/gameInformation';

const extractID = (path: string, information: Record<SceneID, SceneInformation> | Record<GameID, GameInformation>) => {
  for (const id in information) {
    // @ts-ignore
    if (information.hasOwnProperty(id) && path.includes(information[id].url)) {
      return id;
    }
  }
};

export const defaultMetaTitle = 'Kinderwelt Graubünden – Entdecke Graubünden mit Gian und Giachen';
export const defaultMetaDescription =
  'Die Inspirationsplattform für Ausflüge und Entdeckungsreisen in Graubünden. Mit spannenden Spielen und Sommerferien-Tipps für die ganze Familie.';
export const defaultParentImage = androidChromeIcon;

export default ({ path }: { path: string }) => {
  let metaTitle = defaultMetaTitle;
  let metaDescription = defaultMetaDescription;
  let parentImage = defaultParentImage;

  const sceneID = extractID(path, scenesInformation) as SceneID;
  const gameID = extractID(path, gamesInformation) as GameID;
  if (sceneID) {
    ({ metaTitle, metaDescription, parentImage } = scenesInformation[sceneID]);
  } else if (gameID) {
    ({ metaTitle, metaDescription } = gamesInformation[gameID]);
  }

  return (
    <Helmet htmlAttributes={{ lang: 'de' }}>
      <link rel="icon" href={favicon32} type="image/png" sizes="32x32" />
      <link rel="icon" href={favicon16} type="image/png" sizes="16x16" />
      <link rel="apple-touch-icon" href={appleTouchIcon} type="image/png" sizes="180x180" />
      <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="apple-mobile-web-app-title" content="Kinderwelt" />
      <meta name="application-name" content="Kinderwelt" />
      <meta name="theme-color" content="#ffffff" />
      <title>{metaTitle}</title>
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={parentImage} />
      <meta name="description" content={metaDescription} />
      <script async defer src="https://scripts.withcabin.com/hello.js" />
    </Helmet>
  );
};
