import React from 'react';
import SEO from './components/SEO';

// @ts-ignore
import Loadable from '@loadable/component';
const App = Loadable(() => import('./App'));

const Index = ({ pageContext }: { pageContext: { url: string } }) => (
  <div className="root">
    <SEO path={pageContext.url} />
    <App />
    <noscript>Du musst JavaScript in deinem Browser aktivieren, um diese App auszuführen.</noscript>
  </div>
);

export default Index;
