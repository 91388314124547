import davos from '../Summer/scenes/davos.png';
import rhb from '../Summer/scenes/rhb.png';
import savognin from '../Summer/scenes/savognin.png';
import surselva from '../Summer/scenes/surselva.png';
// #WINTER#
// import scene1 from '../Winter/scenes/scene1.png';
// import scene2 from '../Winter/scenes/scene2.png';
// import scene3 from '../Winter/scenes/scene3.png';
// import scene4 from '../Winter/scenes/scene4.png';
// import scene4b from '../Winter/scenes/scene4b.png';
// import scene8 from '../Winter/scenes/scene8.png';
// import scene10 from '../Winter/scenes/scene10.png';
// import scene14 from '../Winter/scenes/scene14.png';
// import scene15 from '../Winter/scenes/scene15.png';
// import scene16 from '../Winter/scenes/scene16.png';
// import scene17 from '../Winter/scenes/scene17.png';

export type SceneID = typeof SceneIDs[number];

export interface SceneInformation {
  url: string;
  parentPartner: string;
  parentTitle: string;
  panoramaLabel: string;
  parentImage: string;
  specialImage?: string;
  parentDescription: string;
  parentNews?: string;
  parentMapLink: string;
  parentPartnerLink: string;
  metaTitle: string;
  metaDescription: string;
  gameUrl?: string;
  gameOverlayTitle?: string;
  gameOverlayText?: string;
}

export const SceneIDs = ['davos', 'rhb', 'savognin', 'surselva'] as const;

export const scenesInformation: Record<SceneID, SceneInformation> = {
  davos: {
    url: '/davos-klosters/madrisa-land',
    parentPartner: 'Davos Klosters',
    parentTitle: 'Madrisa-Land',
    panoramaLabel: 'Davos Klosters',
    parentImage: davos,
    parentMapLink:
      'https://www.google.ch/maps/place/Madrisastrasse+7,+7252+Klosters-Serneus/@46.8844878,9.8734808,17z/data=!4m5!3m4!1s0x4784a8bfe6756c09:0x88ec838c27c4b52d!8m2!3d46.8844842!4d9.8756695',
    parentDescription:
      'Im barrierefreien Madrisa-Land mit traumhafter Aussicht tobt sich Gross und Klein beim Hüpfen, Rutschen und bei Wasserspielen so richtig aus. Für extra Nervenkitzel sorgen Monster- und Familientrottis sowie der 3-D-Bogenpark. Wer gleich auf Madrisa bleiben will, übernachtet in den tollen Baumhütten – ein einmaliges Erlebnis!',
    parentPartnerLink: 'https://www.madrisa.ch/madrisa-land',
    metaTitle: 'Kinderwelt Graubünden – Spiel, Spass und Sonne im Madrisa-Land',
    metaDescription:
      'Mit einem Sommerausflug ins Madrisa-Land, dem grössten Spielplatz der Schweizer Berge, ist Action und Nervenkitzel für die ganze Familie vorprogrammiert.',
    gameUrl: '/davos-klosters/madrisa-land/baumhaus',
    gameOverlayTitle: 'Eine Nacht im Baumhaus für die ganze Familie gewinnen',
    gameOverlayText:
      'Jetzt für den Newsletter anmelden und an der Verlosung einer Übernachtung in einer Madrisa-Baumhütte und weiterer Überraschungspreise teilnehmen. Teilnahmeschluss ist der 25.07.2021.'
  },
  rhb: {
    url: '/rhaetische-bahn/rheinschlucht',
    parentPartner: 'Rhätische Bahn',
    parentTitle: 'Rheinschlucht',
    panoramaLabel: 'Rheinschlucht',
    parentImage: rhb,
    parentMapLink:
      'https://www.google.ch/maps/dir//Islabord,+Bahnhofstrasse,+7104+Versam/@47.0916383,8.4798248,9z/data=!3m1!4b1!4m17!1m6!3m5!1s0x4784dcdae5b6e953:0xc2251b6a74b32da9!2sIslabord!8m2!3d46.803527!4d9.3207119!4m9!1m1!4e1!1m5!1m1!1s0x4784dcdae5b6e953:0xc2251b6a74b32da9!2m2!1d9.3207119!2d46.803527!3e3',
    parentDescription:
      'Zwischen Ilanz und Reichenau hat sich der Rhein während 10 000 Jahren einen Weg durch das Gestein geschaffen. Erlebe die monumentale und einmalige Naturkulisse der Ruinaulta jeweils samstags und sonntags aus den offenen Aussichtswagen des Erlebniszugs Rheinschlucht.',
    parentPartnerLink: 'https://www.rhb.ch/erlebniszug',
    metaTitle: 'Kinderwelt Graubünden – Mit der Rhätischen Bahn durch die Rheinschlucht',
    metaDescription:
      'Diesen Sommer mit den berühmten gelben Cabriolets der Rhätischen Bahn durch die Rheinschlucht fahren und dabei Fahrtwind und Sonne im Gesicht geniessen.',
    gameUrl: '/rhaetische-bahn/rheinschlucht/gaemsebruecke',
    gameOverlayTitle: 'Abenteuerfahrt mit Kinderkondukteur Clà Ferrovia zu gewinnen',
    gameOverlayText:
      'Jetzt für den Newsletter anmelden und an der Verlosung einer Zugfahrt mit Kinder-Kondukteur Clà Ferrovia und weiterer Überraschungspreise teilnehmen. Teilnahmeschluss ist der 19.09.2021.'
  },
  savognin: {
    url: '/savognin/val-surses',
    parentPartner: 'Savognin Bivio Albula',
    parentTitle: 'Val Surses',
    panoramaLabel: 'Val Surses',
    parentImage: savognin,
    parentMapLink: 'https://goo.gl/maps/1xyBkVDaakxETh8Q9',
    parentDescription:
      'So nah an einer anderen Welt. Ferien im grössten Naturpark der Schweiz, dem Parc Ela, mit abwechslungsreichen Themenwegen, wo Langeweile ein Fremdwort bleibt. Badeferien am Lai Barnagn wecken Strandgefühle, und familienfreundliche Ferienwohnungen und Hotels sorgen für eine erholsame Familienzeit.',
    parentPartnerLink: 'http://valsurses.ch/naturspielplatz',
    metaTitle: 'Kinderwelt Graubünden – Aufregende Wanderungen im Val Surses',
    metaDescription:
      'Abwechslungsreiche Wanderungen, spannende Themenwege und schönste Landschaften erwarten Gross und Klein im Parc Ela, dem grössten Naturpark der Schweiz.',
    gameUrl: '/savognin/val-surses/libellensprung',
    gameOverlayTitle: 'Familienferien im Val Surses gewinnen',
    gameOverlayText:
      'Jetzt für den Newsletter anmelden und an der Verlosung dreier Nächte im Resort Surses Alpin und weiterer Überraschungspreise teilnehmen. Teilnahmeschluss ist der 20.06.2021.'
  },
  surselva: {
    url: '/surselva/obersaxen',
    parentPartner: 'Surselva',
    parentTitle: 'Hörspielweg in Obersaxen Mundaun',
    panoramaLabel: 'Surselva',
    parentImage: surselva,
    parentMapLink: 'https://goo.gl/maps/36mRVSZqyXNa6DuQ7',
    parentDescription:
      'Auf dem Hörspielweg begleitet Specht Hilarius die Wanderer auf einer spassigen Entdeckungsreise: Am oberen Dorfrand von Surcuolm startet der kinderwagentaugliche Wanderweg mit 15 Posten auf 3.3 Kilometern. Mit dem Smartphone kann auf jedem Posten eine Geschichte abgespielt werden. Ganz einfach auf jedem Posten den QR-Code scannen und die 15 Holzfiguren erzählen lassen.',
    parentPartnerLink: 'https://www.surselva.info/Media/Touren/Hoerspielweg-Hilarius-in-Surcuolm',
    metaTitle: 'Kinderwelt Graubünden – Auf dem Hörspielweg Hilarius in Obersaxen',
    metaDescription:
      'Der Specht Hilarius bringt die ganze Familie auf Entdeckungsreise in Obersaxen. Auf dem kinderwagentauglichen Themenweg ist Spiel und Spass garantiert.',
    gameUrl: '/surselva/obersaxen/segelmeister',
    gameOverlayTitle: 'Eine Übernachtung für die ganze Familie in Surcuolm gewinnen',
    gameOverlayText:
      'Jetzt für den Newsletter anmelden und an der Verlosung einer Übernachtung im Berggasthaus Cuolm Sura und weiterer Überraschungspreise teilnehmen. Teilnahmeschluss ist der 22.08.2021.'
  }
};

// #WINTER#
// export const SceneIDs = ['1', '2', '3', '4', '4b', '8', '10', '11', '14', '15', '16', '17'] as const;
// export const scenesInformation: Record<SceneID, SceneInformation> = {
//   '1': {
//     url: '/engadin/piz-nair',
//     parentPartner: 'Engadin',
//     parentTitle: 'Piz Nair',
//     panoramaLabel: 'Piz Nair',
//     parentImage: scene1,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//46.497851+,+9.839595/@46.4987204,9.8385991,563m/data=!3m1!1e3!4m7!4m6!1m0!1m3!2m2!1d9.839595!2d46.497851!3e3',
//     parentDescription:
//       'Der Piz Nair liegt oberhalb von St. Moritz im (Ski-)Gebiet Corviglia und ist mit 3057 m ü. M. nach dem Corvatsch der zweithöchste erschlossene Berg im Engadin. Man befindet sich in luftigen Höhen und könnte fast den Himmel berühren – und doch sind Sie hier der Natur ganz nah.',
//     parentPartnerLink: 'https://www.engadin.ch/de/restaurants/piz-nair/',
//     metaTitle: 'in luftigen Höhen auf dem Piz Nair',
//     metaDescription:
//       'Über St. Moritz im Skigebiet Corviglia liegt der Piz Nair in luftiger Höhe, nahe dem Himmel und der Natur – spektakuläre Aussicht und Familienspass garantiert.'
//   },
//   '2': {
//     url: '/rhaetische-bahn/berguen',
//     parentPartner: 'Rhätische Bahn',
//     parentTitle: 'Schlittelwelt Preda-Bergün',
//     panoramaLabel: 'Bergün',
//     parentImage: scene2,
//     parentMapLink:
//       'https://www.google.com/maps/dir//Preda,+7482+Berg%C3%BCn%2FBravuogn/@46.5883313,9.7422372,10822m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x47849cec452a1e5b:0x9b061b2b6358efb9!2m2!1d9.7773448!2d46.5882804',
//     parentDescription:
//       'Die Schlittelwelt Preda/Darlux-Bergün rollt den weissen Teppich aus. Wo im Sommer Autos fahren, kurven im Winter grosse und kleine Schlittelfans auf sechs Kilometern ganz schön rasant von Preda hinunter nach Bergün. Wer noch mehr Nervenkitzel sucht, nimmt die Schlittelbahn von Darlux nach Bergün unter die Kufen: vier Kilometer Gaudi vom Feinsten.',
//     parentPartnerLink: 'http://www.rhb.ch/schlittelwelt',
//     metaTitle: 'Kinderwelt Graubünden – Mit der RhB mitten im UNESCO-Welterbe',
//     metaDescription:
//       'Die Schlittelwelt Preda/Darlux-Bergün rollt den weissen Teppich aus. Wo im Sommer Autos fahren, kurven im Winter grosse und kleine Schlittelfans auf sechs Kilometern ganz schön rasant von Preda hinunter nach Bergün.',
//     gameUrl: '/rhaetische-bahn/berguen/ruabli-jagd'
//   },
//   '3': {
//     url: '/surselva/cuolm-sura',
//     parentPartner: 'Surselva',
//     parentTitle: 'Kinderland Cuolm Sura',
//     panoramaLabel: 'Cuolm Sura',
//     parentImage: scene3,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//Berggasthaus+Cuolm+Sura,+Cuolm+Sura+1,+7138+Surcuolm/@47.0797129,8.4757766,411168m/data=!3m2!1e3!4b1!4m20!1m9!3m8!1s0x4784e03fb40c13b7:0xf27a17adc1de8e61!2sBerggasthaus+Cuolm+Sura!5m2!4m1!1i2!8m2!3d46.7495629!4d9.1453417!4m9!1m1!4e1!1m5!1m1!1s0x4784e03fb40c13b7:0xf27a17adc1de8e61!2m2!1d9.1453419!2d46.7495629!3e3',
//     parentDescription:
//       'Spielerisch und professionell führt die Ski- und Snowboardschule Mundaun die Kids an den Schneesport heran – dank verschiedenen Hilfsmitteln entdecken so alle auf ihre eigene Art das Schnee- und Funparadies im Wintersportgebiet Obersaxen Mundaun Val Lumnezia.',
//     parentPartnerLink: 'https://www.surselva.info/Media/Attraktionen/Kinderland-Cuolm-Sura-Surcuolm',
//     metaTitle: 'Skischule im Kinderland Cuolm Sura',
//     metaDescription:
//       'Mit den modernen Hilfsmitteln der Ski- und Snowboardschule Mundaun finden bestimmt alle Schneehasen ihren Lieblings-Wintersport.',
//     gameUrl: '/surselva/cuolm-sura/schnee-verlochata'
//   },
//   '4': {
//     url: '/savognin/val-surses/tanne',
//     parentPartner: 'Val Surses: Savognin, Bivio',
//     parentTitle: 'So nah dem Kinderskiparadies',
//     panoramaLabel: 'Val Surses',
//     parentImage: scene4,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//Schweizer+Skischule+Savognin,+Veia+Curvanera+1,+7460+Savognin/@46.5953845,9.5929008,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x478491c866f41261:0x3833de5a98e4ce75!2m2!1d9.5950488!2d46.5953732',
//     parentDescription:
//       'In der Val Surses lernen Kinder Ski fahren wie im Paradies. In Savognin schweben sie auf einem der längsten überdachten Zauberteppiche der Schweiz, drehen Kurven am Skikarussell und balancieren durch den Magicpark. Im Kinderskiparadies La Nars in Savognin merken die Kinder gar nicht, wie sie plötzlich Ski fahren. Das Kinderland in Bivio ist nicht ganz so gross, aber der Skiunterricht genauso fantasievoll. Vertraue deine Liebsten unseren Skischulen und Kids-Clubs an und ziehe los ins Winterparadies.',
//     parentPartnerLink: 'https://www.valsurses.ch/de/aktivitaeten/winter/tipps-familien',
//     metaTitle: 'Kinderwelt Graubünden – Kinderskiparadies in der Val Surses',
//     metaDescription:
//       'In der Val Surses lernen Kinder Skifahren wie im Paradies. Vertraue deine Liebsten unseren Skischulen und Kids-Clubs an und ziehe los ins Winterparadies.',
//     gameUrl: '/savognin/val-surses/hasahopsa'
//   },
//   '4b': {
//     url: '/savognin/val-surses/verstecken',
//     parentPartner: 'Val Surses: Savognin, Bivio',
//     parentTitle: 'So nah dem Kinderskiparadies',
//     panoramaLabel: 'Val Surses',
//     parentImage: scene4,
//     specialImage: scene4b,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//Schweizer+Skischule+Savognin,+Veia+Curvanera+1,+7460+Savognin/@46.5953845,9.5929008,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x478491c866f41261:0x3833de5a98e4ce75!2m2!1d9.5950488!2d46.5953732',
//     parentDescription:
//       'In der Val Surses lernen Kinder Ski fahren wie im Paradies. In Savognin schweben sie auf einem der längsten überdachten Zauberteppiche der Schweiz, drehen Kurven am Skikarussell und balancieren durch den Magicpark. Im Kinderskiparadies La Nars in Savognin merken die Kinder gar nicht, wie sie plötzlich Ski fahren. Das Kinderland in Bivio ist nicht ganz so gross, aber der Skiunterricht genauso fantasievoll. Vertraue deine Liebsten unseren Skischulen und Kids-Clubs an und ziehe los ins Winterparadies.',
//     parentPartnerLink: 'https://www.valsurses.ch/de/aktivitaeten/winter/tipps-familien',
//     metaTitle: 'Kinderwelt Graubünden – Kinderskiparadies in der Val Surses',
//     metaDescription:
//       'In der Val Surses lernen Kinder Skifahren wie im Paradies. Vertraue deine Liebsten unseren Skischulen und Kids-Clubs an und ziehe los ins Winterparadies.',
//     gameUrl: '/savognin/val-surses/hasahopsa'
//   },
//   '8': {
//     url: '/rhaetische-bahn/alvaneu',
//     parentPartner: 'Rhätische Bahn',
//     parentTitle: 'Alvaneu (Skateline Albula)',
//     panoramaLabel: 'Alvaneu',
//     parentImage: scene8,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//46.66454,9.613123/@47.0356047,8.5015074,9z/data=!3m1!4b1!4m13!1m7!3m6!1s0x0:0x0!2zNDbCsDM5JzUyLjMiTiA5wrAzNic0Ny4yIkU!3b1!8m2!3d46.66454!4d9.613123!4m4!1m1!4e1!1m0!3e3',
//     parentDescription:
//       "Entspannt mit der Rhätischen Bahn anreisen und rein ins Familienvergnügen: Hand in Hand geht's auf Schlittschuhen durch den verschneiten Winterwald auf der rund 3 km langen Skateline von Alvaneu zurück nach Surava.",
//     parentNews: 'Die Skateline Albula bleibt leider in der Wintersaison 2021/22 geschlossen.',
//     parentPartnerLink: 'https://www.valsurses.ch/de/aktivitaeten/winter/skateline.albula',
//     metaTitle: 'mit der RhB zur Skateline Albula',
//     metaDescription:
//       'Auf Schlittschuhen 3 Kilometer weit Hand in Hand durch den verschneiten Wald gleiten – kann der Winter noch schöner werden?',
//     gameUrl: '/rhaetische-bahn/alvaneu/ruabli-jagd'
//   },
//   '10': {
//     url: '/surselva/obersaxen/weitsprung',
//     parentPartner: 'Surselva',
//     parentTitle: 'Gemschi-Challenge in Obersaxen Mundaun',
//     panoramaLabel: 'Surselva',
//     parentImage: scene10,
//     parentMapLink:
//       'https://www.google.com/maps/dir//Bergbahnen+Obersaxen+Mundaun,+Schnaggabial+10,+7134+Obersaxen/@46.7482981,9.1022753,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47851f04e24264f7:0x24e26b4771964dc9!2m2!1d9.1022753!2d46.7482981',
//     parentDescription:
//       'In Obersaxen ist das «Gemschi» auf Skiern unterwegs. Du auch? Dann suche es auf den Skiern oder dem Snowboard. Es versteckt sich zehnmal im gesamten Skigebiet und wartet mit spannenden Aufgaben auf dich und deine Familie. Es winken tolle Sofortpreise und ein «Gemschi-Superpreis» am Ende der Wintersaison.',
//     parentPartnerLink:
//       'https://www.surselva.info/Ferienregionen/Obersaxen-Mundaun/Winter/Gemschi-Challenge-in-Obersaxen-Mundaun2',
//     metaTitle: 'Kinderwelt Graubünden – Gemschi-Challenge in Obersaxen',
//     metaDescription:
//       'Das «Gemschi» ist in Obersaxen auf den Skiern unterwegs. Findest du es auf der Piste? Es wartet mit spannenden Aufgaben auf dich und deine Familie.',
//     gameUrl: '/surselva/obersaxen/schnee-verlochata'
//   },
//   '11': {
//     url: '/surselva/obersaxen/schlafwettbewerb',
//     parentPartner: 'Surselva',
//     parentTitle: 'Gemschi-Challenge in Obersaxen Mundaun',
//     panoramaLabel: 'Obersaxen',
//     parentImage: scene10,
//     parentMapLink:
//       'https://www.google.com/maps/dir//Bergbahnen+Obersaxen+Mundaun,+Schnaggabial+10,+7134+Obersaxen/@46.7482981,9.1022753,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47851f04e24264f7:0x24e26b4771964dc9!2m2!1d9.1022753!2d46.7482981',
//     parentDescription:
//       'In Obersaxen ist das «Gemschi» auf Skiern unterwegs. Du auch? Dann suche es auf den Skiern oder dem Snowboard. Es versteckt sich zehnmal im gesamten Skigebiet und wartet mit spannenden Aufgaben auf dich und deine Familie. Es winken tolle Sofortpreise und ein «Gemschi-Superpreis» am Ende der Wintersaison.',
//     parentPartnerLink:
//       'https://www.surselva.info/Ferienregionen/Obersaxen-Mundaun/Winter/Gemschi-Challenge-in-Obersaxen-Mundaun2',
//     metaTitle: 'Kinderwelt Graubünden – Gemschi-Challenge in Obersaxen',
//     metaDescription:
//       'Das «Gemschi» ist in Obersaxen auf den Skiern unterwegs. Findest du es auf der Piste? Es wartet mit spannenden Aufgaben auf dich und deine Familie.',
//     gameUrl: '/surselva/obersaxen/schnee-verlochata'
//   },
//   '14': {
//     url: '/engadin/zuoz',
//     parentPartner: 'Engadin',
//     parentTitle: 'Familienskigebiet Zuoz mit Snowli',
//     panoramaLabel: 'Zuoz',
//     parentImage: scene14,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//46.5999722,9.9611667/@47.0011198,8.1840412,8z/data=!3m1!4b1!4m13!1m7!3m6!1s0x0:0x0!2zNDbCsDM1JzU5LjkiTiA5wrA1Nyc0MC4yIkU!3b1!8m2!3d46.599978!4d9.961164!4m4!1m1!4e1!1m0!3e3',
//     parentDescription:
//       'Im grossen, sicheren Gelände mit Zauberteppich und vielen Spielutensilien wird das Skifahrenlernen zum Erlebnis. Unsere erfahrenen KinderskilehrerInnen und Snowli zeigen dir alles Nötige für den Sprung auf die Piste am Berg.',
//     parentPartnerLink: 'https://www.engadin.ch/de/sport/schweizer-ski-snowboardschule-zuoz-la-punt/',
//     metaTitle: 'Abenteuer Skifahrenlernen',
//     metaDescription:
//       'Das sichere Gelände mit vielen Spielutensilien macht das Skifahrenlernen zum Erlebnis – und Snowli ist auch mit dabei!'
//   },
//   '15': {
//     url: '/savognin-bivio-albula/bivio',
//     parentPartner: 'Val Surses: Savognin, Bivio',
//     parentTitle: 'Kinderland Biviolino im Dorf',
//     panoramaLabel: 'Bivio',
//     parentImage: scene15,
//     parentMapLink: 'https://www.google.ch/maps/dir//Kinderland+Biviolino/@46.4678573,9.6481454,17z/',
//     parentDescription:
//       'Wenn der Schneemann lacht, dann ist der Winter erwacht! Bei uns ist niemand zu klein, um ein/e Schneemann-Baumeister/in im Biviolino zu sein. Bereits ab 2 Jahren entdecken hier die Kleinsten spielerisch die Freude am Schnee.',
//     parentPartnerLink: 'https://www.valsurses.ch/de/bergbahnen-erlebnisse/kinderanimation/biviolino-kids-club',
//     metaTitle: 'Schneespass Biviolino',
//     metaDescription:
//       'Spielerisch entdecken im Biviolino bereits Kinder ab 2 Jahren die Freude am Schnee – wer baut den tollsten Schneemann?',
//     gameUrl: '/savognin-bivio-albula/bivio/hasahopsa'
//   },
//   '16': {
//     url: '/rhaetische-bahn/rheinschlucht',
//     parentPartner: 'Rhätische Bahn',
//     parentTitle: 'Rheinschlucht Aussichtsplattform Islabord',
//     panoramaLabel: 'Rheinschlucht',
//     parentImage: scene16,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//Islabord,+Bahnhofstrasse,+7104+Versam/@47.0916383,8.4798248,9z/data=!3m1!4b1!4m17!1m6!3m5!1s0x4784dcdae5b6e953:0xc2251b6a74b32da9!2sIslabord!8m2!3d46.803527!4d9.3207119!4m9!1m1!4e1!1m5!1m1!1s0x4784dcdae5b6e953:0xc2251b6a74b32da9!2m2!1d9.3207119!2d46.803527!3e3',
//     parentDescription:
//       'Die Rheinschlucht zwischen Reichenau und Ilanz gehört zu den wohl spektakulärsten und einzigartigsten Landschaften im Kanton Graubünden. Geniessen Sie mit Ihrer Familie eine Panorama-Fahrt mit der Rhätischen Bahn durch den «Schweizer Grand Canyon» bis zum Bahnhof Versam-Safien, von wo aus es nur wenige Gehminuten sind bis zur Aussichtsplattform Islabord mit ihrem beeindruckenden Blick auf die Rheinschlucht.',
//     parentPartnerLink: 'https://www.rhb.ch/de/freizeit-ausfluege/graubuenden-entdecken/rheinschlucht-ruinaulta',
//     metaTitle: 'mit der RhB durch den Schweizer Grand Canyon',
//     metaDescription:
//       'Die Panorama-Fahrt durch den "Schweizer Grand Canyon" ist für die ganze Familie ein Ereignis – und als krönender Abschluss winkt der Blick vom Islabord.',
//     gameUrl: '/rhaetische-bahn/rheinschlucht/ruabli-jagd'
//   },
//   '17': {
//     url: '/davos-klosters/madrisa-land',
//     parentPartner: 'Davos Klosters',
//     parentTitle: 'Familien-Skiparadies auf Madrisa',
//     panoramaLabel: 'Davos Klosters',
//     parentImage: scene17,
//     parentMapLink:
//       'https://www.google.ch/maps/dir//Madrisastrasse+7,+7252+Klosters-Serneus/@46.8844878,9.8734808,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4784a8bfe6756c09:0x88ec838c27c4b52d!2m2!1d9.8756785!2d46.8844652',
//     parentDescription:
//       'Wie wärs mit einem Familien-Skitag auf Madrisa – dem Familien-Skigebiet par excellence? \nDie beiden Übungsgelände Madrisa-Land (mit drei Zauberteppichen) und Madrisa-Park (mit Kindercross und Race Park) bieten beste Unterhaltung. Die Sesselbahn Schaffürggli ist zudem die erste kindergerechte Sechser-Sesselbahn der Schweiz.',
//     parentPartnerLink: 'https://www.madrisa.ch/familienerlebnisse-winter',
//     metaTitle: 'Kinderwelt Graubünden – Familien-Skiparadies auf Madrisa',
//     metaDescription:
//       'Wie wärs mit einem Skitag auf Madrisa? Die beiden Übungsgelände Madrisa-Land und Madrisa-Park bieten beste Unterhaltung für die ganze Familie.',
//     gameUrl: '/davos-klosters/madrisa-land/winter-volley'
//   }
// };
